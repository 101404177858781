<template>
  <div class="home">
    <div>
      <b-jumbotron
        class="xyz-in"
        xyz="fade-100% duration-5 ease-in-out"
        :style="{
          backgroundImage:
            'url(' + require('../assets/images/home_jumbotron.png') + ')',
        }"
        header="EN ECO rénovation énergétique globale à Langrune-sur-mer"
        header-level="5"
        lead="éligible prime réno'v + CEE"
      >
     
        <b-button
          class="xyz-in"
          xyz="fade-100% duration-15 ease-in-out delay-3 down-5"
          variant="primary"
          to="/contact"
          >Demandez votre devis</b-button
        >
      </b-jumbotron>
    </div>
    <b-container class="card_home">
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col lg="6">
            <b-card-body>
              <b-card-text>
                <h3 class="card-title">
                  <span class="car-title_underlign">Professionnel</span> et
                  fiable
                </h3>
                <p>
                  EN ECO, <strong>spécialiste en rénovation énergétique globale</strong>, vous propose de faire des
                  économies d'énergie pour contribuer à la sauvegarde de notre
                  planète en diminuant les gaz à effet de serre par la réduction
                  de vos consommation énergétiques.
                </p>
                <p>
                  En fonction de vos besoins et de votre budget, nos conseillers
                  sauront vous guider vers ce qu'il a de mieux pour vous et
                  votre habitation. Chaque maison est unique, <strong>nous travaillons
                  avec 100% de visites techniques.</strong> Notre directeur technique
                  répondra à toutes vos questions lors de sa venue pour une
                  validation de la faisabilité de vos projets.
                </p>
                <p>
                  Implanté sur Langrune sur mer, nous nous déplaçons sur toute
                  la Normandie. Soucieux de conserver une relation de proximité.
                </p>
                <p>
                  <strong>Nous sommes éligible RGE</strong>, ce qui garantit la qualité et la
                  durabilité de nos matériaux et installations. De plus, nous
                  pouvons vous faire bénéficier du <strong>crédit d'impôt et autres
                  subventions.</strong>
                </p>
                <p>
                  <strong>Contactez-nous pour une demande de visite technique gratuite.</strong>
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col lg="6">
            <b-card-img
              src='../assets/images/home_illustration.png'
              alt="Image"
              class="rounded-0"
            ></b-card-img>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import "@animxyz/core";
export default {
  name: "Home",
  metaInfo: {
      name: 'En Eco En normandie  Accueil',
      title: 'Accueil',
      titleTemplate: '%s | EN ECO En Normandie',
      meta: [
                { name: 'description', content:  "En eco en normandie vous propose ses services certifiés RGE pour isoler vos maisons par l'extérieur ou depuis vos sous-sol et combles"},
                { property: 'og:title', content: "En Eco En Normandie - Votre entreprise d'isolation à un euro certifiée RGE en Normandie"},
                { property: 'og:site_name', content: 'En Eco En Normandie'},
                {property: 'og:type', content: 'website'},
                 {property: 'og:image', content: '../assets/images/home_jumbotron.png'},
            ]
    }
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: 15px;
  .jumbotron {
    border-radius: 0;
    text-align: center;
    color: #fbfbfd;
    background-size: cover;
    height: 600px;
    overflow: hidden;
    h1 {
      margin-top: 20px;
      font-weight: 700;
      letter-spacing: 1px;
    }
    .lead {
      margin-top: 40px;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 50px;
    }
    .btn-primary {
      color: #fff;
      background-color: #5ea669;
      border: 1px solid #5ea669;
      margin-top: 30px;
      &:hover {
        background-color: transparent;
        border-color: #fff;
        transition: all 600ms ease;
      }
    }
    @media only screen and (max-width: 720px) {
      h1 {
        font-size: 3rem;
      }
    }
  }
  .card_home {
    display: block;
    margin: 200px auto;
    .card{
      border-color: transparent;
    }
    .card-body {
      padding: 0;
    }
    .card-text {
      margin-top: 30px;
      font-size: 15px;
      width: 90%;
    }
    h3 {
      margin-bottom: 25px;
      color: #285b84;
      font-weight: 600;
      letter-spacing: 1px;
      span {
        padding-bottom: 3px;
        border-bottom: 1px solid #285b8467;
      }
    }
    & > p {
      display: block;
      margin-top: 100px;
    }
  }
}
</style>
